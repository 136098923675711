import { API_HOST, INTERNAL_API_HOST, parseResponseBody } from "./apiHelpers";

interface DealsResponse {
  deals: Array<StoreDeal>;
  pagination: Pagination;
}
const parseListResponse = (body): DealsResponse => ({
  deals: body["data"],
  pagination: body["meta"],
});

export const getDeals = async (
  siteCode: string,
  language = "en",
  page = 1,
  perPage = 10
): Promise<DealsResponse> => {
  const params = [
    `siteCode=${siteCode}`,
    `language=${language}`,
    `perPage=${perPage}&page=${page}`,
  ].join("&");

  const url = `${API_HOST}/deals?${params}`;
  return fetch(url)
    .then((r) => r.json())
    .then(parseListResponse)
    .catch(() => {
      return {
        deals: [],
        pagination: {},
      };
    });
};

const fetchCouponDeals = async (
  apiHost: string,
  siteCode: string,
  language: string,
  categorySlug: string,
  page = 1
): Promise<DealsResponse> => {
  const params = [
    `siteCode=${siteCode}`,
    `language=${language}`,
    `page=${page}`,
    `perPage=18`,
    `dealType=coupon`,
  ];

  if (categorySlug !== "all-stores") {
    params.push(`categorySlug=${categorySlug}`);
  }

  const url = `${apiHost}/deals?${params.join("&")}`;
  return fetch(url)
    .then((r) => r.json())
    .then(parseListResponse)
    .catch(() => {
      return {
        deals: [],
        pagination: {},
      };
    });
};

export const getCouponDeals = async (
  siteCode: string,
  language = "en",
  categorySlug: string,
  page: number
): Promise<DealsResponse> => {
  return fetchCouponDeals(API_HOST, siteCode, language, categorySlug, page);
};

export const getCouponDealsInternally = async (
  siteCode: string,
  language = "en",
  categorySlug: string,
  page: number
): Promise<DealsResponse> => {
  return fetchCouponDeals(
    INTERNAL_API_HOST,
    siteCode,
    language,
    categorySlug,
    page
  );
};

export const createDealDeeplink = async (
  dealId: string,
  locale: string,
  appType: string,
  accessToken: string,
  siteCode: string
): Promise<Deeplink> => {
  const url = `${API_HOST}/deeplink/deal?language=${locale}&siteCode=${siteCode}`;
  return fetch(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({ data: { dealId, appType, siteCode } }),
  })
    .then((r: Response) => r.json())
    .then((body: DeeplinkDealResponse) =>
      parseResponseBody<DeeplinkDealResponse, Deeplink>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};
