import Button, { ButtonWithPreview } from "@components/Button";
import DealCouponModal from "@components/_shared/DealCouponModal";
import Image from "@components/_shared/NextImage";
import TextDirection from "@components/_shared/TextDirection";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import { getDateDiff, GetDateDiffType, UTCtoLocalTime } from "@helpers/date";
import { getLocaleInUrl } from "@helpers/site";
import ChevronIcon from "@icons/chevron.svg";
import { DealCouponModalCtaType } from "@shopcashTypes/dealCouponModalCtaType";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import styles from "./DealItem.module.scss";

const useExpiryText = (deal: StoreDeal): string => {
  const translate = useTranslations();

  if (!deal) return "";
  const { value, unit }: GetDateDiffType = getDateDiff(
    new Date(UTCtoLocalTime(deal.endAt)),
    new Date()
  );
  let expiryText: string;
  if (["second", "seconds"].includes(unit)) {
    expiryText = translate("store_expires_within_title");
  } else {
    const expiry = translate(`num_${unit}`, [value]);
    expiryText = translate("store_expires_in_title", [expiry]);
  }
  return expiryText;
};

interface DealItemProps {
  deal?: StoreDeal;
  onClick?: () => void;
  onDealCouponModalItemClick?: (
    deal: StoreDeal,
    ctaType: DealCouponModalCtaType
  ) => void;
}

const DealItem: React.FC<DealItemProps> = ({
  deal,
  onClick,
  onDealCouponModalItemClick,
}) => {
  const { isRtl, isMobile } = useSiteConfig();
  const [showModal, setShowModal] = useState(false);
  const translate = useTranslations();
  const expiryText = useExpiryText(deal);
  const store = deal?.store;
  const { locale, defaultLocale } = useSiteConfig();
  const localeInUrl = getLocaleInUrl(defaultLocale, locale);
  const { user } = useAuth();
  const router = useRouter();

  const handleOnClick = (shouldShowModal: boolean) => {
    if (onClick) onClick();

    if (shouldShowModal) {
      setShowModal(true);
    } else {
      if (user) {
        const dealHandoffUrl = `${localeInUrl}/handoff/deal/${deal.id}`;
        window.open(dealHandoffUrl, "_blank");
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        router.push({ pathname: `/store/${deal.store.slug}` });
      }
    }
  };

  const handleDealCouponModalClose = () => {
    setShowModal(false);
  };

  const handleDealCouponModalItemClick = (
    ctaType: DealCouponModalCtaType
  ): void => {
    onDealCouponModalItemClick(deal, ctaType);
  };

  const linkUrl = (user) => {
    return user ? `/handoff/deal/${deal.id}` : `/store/${deal.store.slug}`;
  };

  return (
    <>
      {showModal && deal.couponCode && (
        <DealCouponModal
          show={showModal}
          deal={deal}
          showViewMore={true}
          onEventTrack={handleDealCouponModalItemClick}
          onCloseClick={handleDealCouponModalClose}
        ></DealCouponModal>
      )}

      <div
        className={clsx(
          styles.container,
          !deal && styles.loading,
          isRtl && styles.rtl
        )}
      >
        <div className={styles.storeLogoContainer}>
          {deal ? (
            <Image src={store.logoUrl} layout="fill" objectFit="contain" />
          ) : (
            <div className={styles.storeLogoSkeleton}></div>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.storeName}>
            <TextDirection text={store?.name} />
          </div>
          {deal?.title && (
            <div className={clsx(styles.title, styles.lineClamp)}>
              {deal?.title}
            </div>
          )}

          {store?.storeType == 1 && (
            <div className={styles.cashbackText}>
              {store.featuredCashback && "+"} {store?.cashbackText}{" "}
            </div>
          )}

          {deal?.endAt && <div className={styles.expiry}>{expiryText}</div>}

          {deal?.couponCode && isMobile && (
            <div className={styles.previewButtonContainer}>
              <ButtonWithPreview previewText={deal.couponCode}>
                {translate("show_code")}
              </ButtonWithPreview>
            </div>
          )}

          {!deal && (
            <div className={styles.skeletonTexts}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
        <div className={styles.cta}>
          {deal && isMobile && (
            <>
              {deal.couponCode ? (
                <div
                  className={styles.chevronContainer}
                  onClick={() => handleOnClick(true)}
                >
                  <ChevronIcon className={styles.chevron} />
                </div>
              ) : (
                <Link href={linkUrl(user)}>
                  <a onClick={() => handleOnClick(false)}>
                    <ChevronIcon className={styles.chevron} />
                  </a>
                </Link>
              )}
            </>
          )}

          {deal && !isMobile && (
            <>
              {deal.couponCode && (
                <Button
                  variant={VariantButton.PrimaryFolded}
                  onClick={() => handleOnClick(true)}
                  data-testid="deal-item-button"
                  fullWidth
                  rtl={isRtl}
                >
                  <span data-sibling-text={translate("visit_store")}>
                    {translate("show_code")}
                  </span>
                </Button>
              )}
              {!deal.couponCode && (
                <Button
                  variant={VariantButton.Primary}
                  onClick={() => handleOnClick(false)}
                  data-testid="deal-item-button"
                  fullWidth
                >
                  <span data-sibling-text={translate("show_code")}>
                    {translate("visit_store")}
                  </span>
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DealItem;
