import { getDeals } from "@api/deals";
import { useSiteConfig } from "@context/siteConfig";
import { useEffect, useState } from "react";
import useSWR from "swr";

const useAllDeals = () => {
  const [allDealsPage, setAllDealsPage] = useState(1);
  const [allDeals, setAllDeals] = useState<StoreDeal[]>();
  const [dealLocale, setDealLocale] = useState<string>();
  const { siteCode, locale } = useSiteConfig();

  useEffect(() => {
    if (locale !== dealLocale) {
      setAllDeals(undefined);
      setDealLocale(locale);
      setAllDealsPage(1);
    }
  }, [locale]);
  const { data: response } = useSWR(
    dealLocale && siteCode
      ? ["allDeals", siteCode, dealLocale, allDealsPage]
      : null,
    (_: string, siteCode: string, dealLocale: string, page: number) =>
      getDeals(siteCode, dealLocale, page, 10)
  );
  const { deals: newDeals, pagination } = response || {};

  const hasMoreDeals = allDealsPage < pagination?.totalPages;

  useEffect(() => {
    if (newDeals && !allDeals) {
      setAllDeals(newDeals);
    } else if (newDeals && allDeals.length < allDealsPage * 10) {
      setAllDeals(allDeals.concat(newDeals));
    }
  }, [newDeals]);

  const loadMoreDeals = () => {
    setAllDealsPage(allDealsPage + 1);
  };

  return {
    allDeals,
    currentPage: pagination?.currentPage,
    loadMoreDeals,
    hasMoreDeals,
    loadingDeals: response == undefined,
  };
};

export default useAllDeals;
