import Image from "@components/_shared/NextImage";
import clsx from "clsx";
import Link from "next/link";
import { FC } from "react";
import styles from "./StoreLogo.module.scss";

type StoreLogoProps = {
  store?: StoreSummary;
};

const StoreLogo: FC<StoreLogoProps> = ({ store }) => {
  return (
    <>
      {store === undefined && <LogoSkeleton />}
      {store?.logoUrl && <Logo store={store} />}
    </>
  );
};

const LogoSkeleton = () => (
  <div className={clsx(styles.frame, styles.loading)}>
    <div className={styles.container} />
  </div>
);

const Logo = ({ store }) => (
  <div className={styles.frame}>
    <div className={styles.container}>
      {store?.slug ? (
        <Link href={`/store/${store.slug}`}>
          <a>
            <Image
              className={styles.image}
              src={store.logoUrl}
              alt="Store Logo"
              layout="fill"
            />
          </a>
        </Link>
      ) : (
        <Image
          className={styles.image}
          src={store.logoUrl}
          alt="Store Logo"
          layout="fill"
        />
      )}
    </div>
  </div>
);

export default StoreLogo;
