import StoreLogo from "@components/StoreLogo";
import clsx from "clsx";
import styles from "./DiscoverStores.module.scss";

interface DiscoverStoresProps {
  title: string;
  isRtl: boolean;
  stores?: Array<StoreSummary>;
  onStoreClick?: (store: StoreSummary, index: number) => void;
}

const skeletonItems = [...new Array(12)];

const DiscoverStores: React.FC<DiscoverStoresProps> = ({
  stores,
  title,
  isRtl,
  onStoreClick,
}) => {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.title, isRtl && styles.rtl)}>{title}</div>
      <div className={styles.box}>
        {stores?.length > 0
          ? stores.map((store, index) => (
              <div
                onClick={() => onStoreClick(store, index)}
                key={store.id}
                className={styles.logo}
              >
                <StoreLogo store={store}></StoreLogo>
              </div>
            ))
          : skeletonItems.map((_item, index) => (
              <div key={index} className={styles.logo}>
                <StoreLogo></StoreLogo>
              </div>
            ))}
      </div>
    </div>
  );
};

export default DiscoverStores;
