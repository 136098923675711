import {
  API_HOST,
  INTERNAL_API_HOST,
  INTERNAL_USER_AGENT,
  parseResponseBody,
} from "./apiHelpers";
import { StoreDetails } from "@shopcashTypes/storeDetails";

const parseStoreResponse = (body: StoresResponse): StoresParseResponse => ({
  stores: body["data"],
  pagination: body["meta"],
});

export const getStores = async (
  categorySlug: string,
  siteCode: string,
  appType: string,
  language = "en",
  page = 1,
  perPage = 30,
  orderBy = "name"
): Promise<StoresParseResponse> => {
  let params = [
    `appType=${appType}`,
    `siteCode=${siteCode}`,
    `language=${language}`,
    `perPage=${perPage}`,
    `page=${page}`,
    `orderBy=${orderBy}`,
  ].join("&");

  if (categorySlug) {
    params = params + `&categorySlug=${categorySlug}`;
  }
  const url = `${API_HOST}/stores?${params}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then(parseStoreResponse)
    .catch(() => ({ stores: [], pagination: {} }));
};

export const getStoresInternally = async (
  categorySlug: string,
  siteCode: string,
  appType: string,
  language = "en",
  page = 1,
  perPage = 30,
  orderBy = "name"
): Promise<StoresParseResponse> => {
  let params = [
    `appType=${appType}`,
    `siteCode=${siteCode}`,
    `language=${language}`,
    `perPage=${perPage}`,
    `page=${page}`,
    `orderBy=${orderBy}`,
  ].join("&");

  if (categorySlug) {
    params = params + `&categorySlug=${categorySlug}`;
  }
  const url = `${INTERNAL_API_HOST}/stores?${params}`;

  return fetch(url)
    .then((r: Response) => r.json())
    .then(parseStoreResponse)
    .catch(() => ({ stores: [], pagination: {} }));
};

export const getStoreDetails = async (
  slug: string,
  siteCode: string,
  appType: string,
  language = "en",
  accessToken = ""
): Promise<StoreDetails> => {
  const params = [
    `?appType=${appType}`,
    `siteCode=${siteCode}`,
    `language=${language}`,
  ].join("&");
  const url = `${API_HOST}/stores/${slug}${params}`;

  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }

  return fetch(url, {
    headers: headers,
  })
    .then((r: Response) => r.json())
    .then((body: StoreDetailsResponse) =>
      parseResponseBody<StoreDetailsResponse, StoreDetails>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

// TODO refactor to share code with getStoreDetails
export const getStoreDetailsInternally = async (
  slug: string,
  siteCode: string,
  appType: string,
  language = "en"
): Promise<StoreDetails> => {
  const params = [
    `?appType=${appType}`,
    `siteCode=${siteCode}`,
    `language=${language}`,
  ].join("&");
  const url = `${INTERNAL_API_HOST}/stores/${slug}${params}`;

  const headers = new Headers({ "User-Agent": INTERNAL_USER_AGENT });
  return fetch(url, {
    headers: headers,
  })
    .then((r) => r.json())
    .then((body: StoreDetailsResponse) =>
      parseResponseBody<StoreDetailsResponse, StoreDetails>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const createStoreDeeplink = async (
  storeId: string,
  locale: string,
  appType: string,
  accessToken: string,
  siteCode: string
): Promise<Deeplink> => {
  const url = `${API_HOST}/deeplink/store?language=${locale}&siteCode=${siteCode}`;
  return fetch(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({ data: { storeId, appType, siteCode } }),
  })
    .then((r: Response) => r.json())
    .then((body: DeeplinkStoreResponse) =>
      parseResponseBody<DeeplinkStoreResponse, Deeplink>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const addFavStore = async (
  storeId: string,
  accessToken: string
): Promise<Response> => {
  const url = `${API_HOST}/account/favourite-stores/`;
  const data = {
    data: {
      storeId: storeId,
    },
  };

  return fetch(url, {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((r) => r)
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const removeFavStore = async (
  storeId: string,
  accessToken: string
): Promise<Response> => {
  const url = `${API_HOST}/account/favourite-stores/${storeId}`;

  return fetch(url, {
    method: "DELETE",
    cache: "no-store",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((r) => r)
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getTrendingStores = async (
  siteCode: string,
  language = "en",
  perPage = 30
): Promise<StoresParseResponse> => {
  const params = `siteCode=${siteCode}&language=${language}&perPage=${perPage}&page=1`;
  const url = `${API_HOST}/trending-stores?${params}`;
  return fetch(url)
    .then((r) => r.json())
    .then(parseStoreResponse)
    .catch(() => ({ stores: [], pagination: {} }));
};

export const getTrendingStoresInternally = async (
  siteCode: string,
  language = "en",
  perPage = 30
): Promise<StoresParseResponse> => {
  const params = `siteCode=${siteCode}&language=${language}&perPage=${perPage}&page=1`;
  const url = `${INTERNAL_API_HOST}/trending-stores?${params}`;
  const headers = {
    headers: new Headers({ "User-Agent": INTERNAL_USER_AGENT }),
  };

  return fetch(url, headers)
    .then((r) => r.json())
    .then(parseStoreResponse)
    .catch(() => ({ stores: [], pagination: {} }));
};

export const getDiscoverStores = async (
  siteCode: string,
  language = "en",
  perPage = 30
): Promise<StoresParseResponse> => {
  const params = `siteCode=${siteCode}&language=${language}&perPage=${perPage}&page=1`;
  const url = `${API_HOST}/discover-stores?${params}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then(parseStoreResponse)
    .catch(() => ({ stores: [], pagination: {} }));
};

export const getStoreCollections = async (
  siteCode: string,
  appType: string,
  language = "en"
): Promise<StoreCollection[]> => {
  const params = `appType=${appType}&siteCode=${siteCode}&language=${language}`;
  const url = `${API_HOST}/store-collections?${params}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: StoreCollectionResponse) =>
      parseResponseBody<StoreCollectionResponse, Array<StoreCollection>>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};
