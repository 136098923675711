import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import clsx from "clsx";
import styles from "./AllDeals.module.scss";
import DealItem from "./DealItem";

interface AllDealsProps {
  deals?: Array<StoreDeal>;
  onDealClick?: (deal: StoreDeal, index: number) => void;
  onDealCouponModalItemClick?: (deal, ctaType) => void;
}

const skeletonItems = Array.from(Array(5).keys());

const AllDeals: React.FC<AllDealsProps> = ({
  deals,
  onDealClick,
  onDealCouponModalItemClick,
}) => {
  const { isRtl } = useSiteConfig();
  const translate = useTranslations();

  return (
    <section
      className={clsx(
        styles.container,
        !deals && styles.loading,
        isRtl && styles.rtl
      )}
    >
      <div className={styles.title}>
        <div>{translate("infinite_deals")}</div>
      </div>
      <div className={styles.dealsContainer} data-testid="all-deals-container">
        {deals
          ? deals.map((d, i) => (
              <div
                key={d.id}
                className={styles.deal}
                data-testid="all-deals-deal"
              >
                <DealItem
                  deal={d}
                  onClick={() => {
                    onDealClick(d, i);
                  }}
                  onDealCouponModalItemClick={onDealCouponModalItemClick}
                />
              </div>
            ))
          : skeletonItems.map((i) => (
              <div key={i} className={styles.deal}>
                <DealItem />
              </div>
            ))}
      </div>
    </section>
  );
};

export default AllDeals;
